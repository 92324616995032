import request from '../request'
import download from '../download'

export function fetchList(params) {
    return request({
        url: `/finance-service/designAccount/list`,
        params,
    })
}

// 上传 Excel 文件
export function upload(data) {
    return request({
        url: `/finance-service/designAccount/earlyData`,
        method: "post",
        data
    })
}

// 导出收入调整凭证
export function downloadIncomeCertificate(data) {
    return download({
        url: '/finance-service/designAccount/exportIncome',
        data,
    })
}

// 导出分包调整凭证
export function downloadSubPackageCertificate(data) {
    return download({
        url: '/finance-service/designAccount/exportSub',
        data,
    })
}
