

<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-date-picker mode="year" @panelChange="panelChange" :value="form.year" format="YYYY" :open="open"
                @openChange="openChange" style="width: 80px" :allowClear="false" />
            </a-form-model-item>

            <a-form-model-item>
              <a-month-picker format="MM" placeholder="月份" style="width: 150px" v-model="form.month" />
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="财务编号" style="width: 150px" v-model="form.ss" />
            </a-form-model-item>
            <a-form-model-item>
              <a-input placeholder="设计编号" style="width: 150px" v-model="form.designCode" />
            </a-form-model-item>
            <a-form-model-item>
              <a-input placeholder="项目名称" style="width: 150px" v-model="form.projectName" />
            </a-form-model-item>

            <a-form-model-item>
              <a-select placeholder="部门" show-search :filter-option="$selectFilterOption" style="width: 150px"
                v-model="form.selectedDepartment">
                <a-select-option v-for="item in organizationList" :key="item.uniqueName" :value="item.uniqueName">{{
                                  item.uniqueName }}</a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button-group>
              <a-tooltip>
                <template slot="title"> 导入初期数据 </template>
                <Uploader />
              </a-tooltip>
              <a-tooltip>
                <template slot="title"> 导出台账 </template>
                <a-button @click="download" icon="file-excel"> </a-button>
              </a-tooltip>
              <a-tooltip>
                <template slot="title"> 导出收入调整凭证 </template>
                <a-button @click="getCertificate('income')" :loading="downloadCertificateLoading"
                  icon="vertical-align-bottom">
                </a-button>
              </a-tooltip>
              <a-tooltip>
                <template slot="title"> 导出分包调整凭证 </template>
                <a-button @click="getCertificate" :loading="downloadCertificateLoading" icon="download">
                </a-button>
              </a-tooltip>
            </a-button-group>
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table bordered :data-source="list" :loading="loading" :pagination="false" :scroll="{ x: 4000 }">
        <a-table-column title="序号" width="40px" align="center" fixed="left">
          <template slot-scope="text, row, index">
            {{ index + 1 }}
          </template>
        </a-table-column>
        <a-table-column title="月份" data-index="month" ellipsis="true" width="40px" align="center" fixed="left" />

        <a-table-column title="财务编号" data-index="ss" ellipsis="true" width="120px" fixed="left" />
        <a-table-column title="设计编号" data-index="designCode" ellipsis="true" width="120px" fixed="left" />

        <a-table-column title="项目名称" data-index="projectName" ellipsis="true" width="400px" fixed="left" />

        <a-table-column title="收入合同额(元)" align="right">
          <template slot-scope="text">
            <span :style="moneyColor(text.contractAmount)">
              {{ moneyFilter(text.contractAmount) }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="分包合同额(元)" align="right">
          <template slot-scope="text">
            <span :style="moneyColor(text.subpackageAmount)">
              {{ moneyFilter(text.subpackageAmount) }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="所属部门" data-index="deptName" width="180px" />
        <a-table-column title="部门编码" data-index="deptCode" />
        <a-table-column title="项目类型">
          <template slot-scope="text">
            <DataDictFinder dictType="projectType" :dictValue="text.prjType" />
          </template>
        </a-table-column>
        <a-table-column title="客户名称" data-index="customerName" width="240px" />
        <a-table-column title="客商编码" data-index="customerCode" />

        <a-table-column title="本月进度" align="right">
          <template slot-scope="text"> {{ text.progressMonth }}% </template>
        </a-table-column>
        <a-table-column title="累计进度" align="right">
          <template slot-scope="text">
            {{ text.progressAccumulated }}%
          </template>
        </a-table-column>

        <a-table-column title="本月开票额(元)" align="right">
          <template slot-scope="text">
            <span :style="moneyColor(text.invoiceMonth)">
              {{ moneyFilter(text.invoiceMonth) }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="累计开票额(元)" align="right">
          <template slot-scope="text">
            <span :style="moneyColor(text.invoiceAccumulated)">
              {{ moneyFilter(text.invoiceAccumulated) }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="本月应确认收入(元)" align="right">
          <template slot-scope="text">
            <span :style="moneyColor(text.incomeMonth)">
              {{ moneyFilter(text.incomeMonth) }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="累计应确认收入(元)" align="right">
          <template slot-scope="text">
            <span :style="moneyColor(text.incomeAccumulated)">
              {{ moneyFilter(text.incomeAccumulated) }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="本月应调整收入(元)" align="right">
          <template slot-scope="text">
            <span :style="moneyColor(text.incomeAdjustedMonth)">
              {{ moneyFilter(text.incomeAdjustedMonth) }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="累计应调整收入(元)" align="right">
          <template slot-scope="text">
            <span :style="moneyColor(text.incomeAdjustedAccumulated)">
              {{ moneyFilter(text.incomeAdjustedAccumulated) }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="本月账面已确认分包成本(元)" align="right">
          <template slot-scope="text">
            <span :style="moneyColor(text.paperSubpackCostMonth)">
              {{ moneyFilter(text.paperSubpackCostMonth) }}
            </span>
          </template>
        </a-table-column>

        <a-table-column title="截至本月累计账面已确认分包成本(元)" align="right">
          <template slot-scope="text">
            <span :style="moneyColor(text.paperSubpackCostAccumulated)">
              {{ moneyFilter(text.paperSubpackCostAccumulated) }}
            </span>
          </template>
        </a-table-column>

        <a-table-column title="截至本月累计应确认分包成本(元)(不含税)" align="right">
          <template slot-scope="text">
            <span :style="moneyColor(text.subpackCostAccumulated)">
              {{ moneyFilter(text.subpackCostAccumulated) }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="截至本月累计应调整分包成本(元)(不含税)" align="right">
          <template slot-scope="text">
            <span :style="moneyColor(text.subpackCostAdjustedAccumulated)">
              {{ moneyFilter(text.subpackCostAdjustedAccumulated) }}
            </span>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>


<script>
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";

import {
  fetchList,
  downloadIncomeCertificate,
  downloadSubPackageCertificate,
} from "@/api/finance/standing-book";
import Uploader from "./components/uploader.vue";
import { downloadByHtml } from "@/utils/xlsx";
import moment from "moment";
import { saveAs } from "file-saver";

export default {
  name: "standingBook",

  mixins: [organization, watermark],

  components: {
    Uploader,
  },

  data() {
    return {
      form: {
        year: moment(),
      },
      open: false,

      loading: false,
      list: [],

      uploadLoading: false,

      downloadCertificateLoading: false, // 导出凭证 loading
    };
  },

  activated() {
    this.getList();
  },

  methods: {
    // 年份选择器
    panelChange(value) {
      this.form.year = value;
      this.open = false;
      this.getList();
    },
    // 年份选择器面板显示隐藏
    openChange(value) {
      this.open = value;
    },

    getList() {
      this.loading = true;
      fetchList({
        ...this.form,
        year: this.form.year.format("YYYY"),
        month: this.form.month ? this.form.month.format("M") : undefined,
      })
        .then((res) => {
          if (Array.isArray(res)) {
            this.list = Object.freeze(res);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    query() {
      this.getList();
    },

    reset() {
      this.form = {
        year: moment(),
      };
      this.getList();
    },

    moneyFilter(money) {
      if (typeof money !== "number" || !money) {
        return 0;
      }

      const moneyStr = String(money);
      const arr = moneyStr.split(".");
      if (arr.length > 1) {
        return this.separateMoney(arr[0]) + "." + arr[1];
      } else {
        return this.separateMoney(moneyStr);
      }
    },

    separateMoney(moneyStr) {
      let arr = moneyStr.split("").reverse();

      let result = [];

      for (let i = 0; i < arr.length; i++) {
        if (i && i % 3 === 0) {
          result.push(",");
        }
        result.push(arr[i]);
      }

      return result.reverse().join("");
    },

    moneyColor(money) {
      if (parseFloat(money) < 0) {
        return {
          color: "#f00",
        };
      } else {
        return {
          color: "#1890ff",
        };
      }
    },

    setUploadLoading(payload) {
      this.uploadLoading = payload;
    },

    download() {
      downloadByHtml(document.getElementsByTagName("table")[0], "设计台账");
    },

    getCertificate(type) {
      const year = this.form.year.format("YYYY");
      const completeDate = this.form.year.format("YYYY-MM-DD");

      const month = this.form.month ? this.form.month.format("MM") : undefined;

      let date;
      if (month) {
        date = `${year}-${month}-01`;
      } else {
        date = completeDate;
      }

      this.downloadCertificateLoading = true;

      if (type === "income") {
        downloadIncomeCertificate({
          date,
        })
          .then((blob) => {
            saveAs(blob, "收入调整凭证.xlsx");
          })
          .finally(() => {
            this.downloadCertificateLoading = false;
          });
      } else {
        downloadSubPackageCertificate({
          date,
        })
          .then((blob) => {
            saveAs(blob, "分包调整凭证.xlsx");
          })
          .finally(() => {
            this.downloadCertificateLoading = false;
          });
      }
    },
    getSubPackageCertificate() {
      this.downSubPackageCertificateLoading = true;
    },
  },
};
</script>
