<template>
  <span>
    <a-button
      :loading="uploadLoading"
      icon="upload"
      @click="onClick"
    ></a-button>
    <input ref="uploader" id="uploader" type="file" @change="onChange" />
  </span>
</template>

<script>
import { upload } from "@/api/finance/standing-book";
export default {
  data() {
    return {
      uploadLoading: false,
    };
  },

  methods: {
    onClick() {
      this.$refs.uploader.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.uploadLoading = true;

      upload(data)
        .then((res) => {
          console.log("upload res", res);
          this.$message.success("上传成功！");
        })
        .finally(() => {
          this.uploadLoading = false;
          e.target.value = null;
        });
    },
  },
};
</script>

<style lang="less" scoped>
#uploader {
  display: none;
}
</style>